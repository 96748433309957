import React from 'react'
import isEqual from 'react-fast-compare'
import { View } from 'react-native'
import { Blocks } from 'styles/App'

const Block = ( { style, children } ) => {
	let styles = [ Blocks.content, style ];
	
	return <View style={ styles }>{children}</View>
}

export default React.memo( Block, isEqual );