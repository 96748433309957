import { default as i18n } from "i18next";
import { initReactI18next } from "react-i18next";
import 'utils/console-prefix';
import { default as HelperLocale } from "utils/helper/locale";
import localesNL from "locales/nl/translation.json";
import _ from 'lodash'

i18n
	.use( initReactI18next )
	.init({
		resources: {
			'nl-NL': {
				translation: localesNL
			}
		},
		lng: 'nl-NL',
		fallbackLng: 'nl-NL',
		supportedLngs: [
			'nl-NL'
		],
		ns: 'translation',
		defaultNS: 'translation',
		debug: false,
		partialBundledLanguages: true,
		interpolation: {
			format: function(value, format, lng) {
				if( value !== undefined ) {
					if ( format === 'uppercase' ) {
						return value.toUpperCase();
					}
					
					if ( format === 'lowercase' ) {
						return value.toLowerCase();
					}
					
					//if ( value instanceof Date ) {
					//	return HelperMoment.moment( value )
					//	                   .format( format );
					//}
					
					return value;
				}
				
				return '';
			}
		}
	}, function( err, t) {
		// initialized and ready to go!
		const newLocale = HelperLocale.getDeviceLocale();
		
		i18n.changeLanguage( newLocale );
		//i18n.reloadResources( newLocale );
	});

/**
 * Function for combining the current translations with new ones
 * @param result
 * @returns {any}
 */
const overwriteTranslations = ( result ) => {
	const { language, namespace, translations } = result;
	
	const current = _.get( i18n.options.resources, [ language, namespace ], {} );
	
	return _.merge( current, translations );
}

export default i18n;
