import React from 'react'
import isEqual from 'react-fast-compare'
import { Image, View } from 'react-native'
import LinearGradientBackground, { LinearGradientBackgroundPatterns } from 'components/atoms/LinearGradientBackground'
import { gradients } from 'styles/App';
import { HeaderStyles } from 'styles/components/molecules/Header';
import ContentCentered from 'components/atoms/ContentCentered'
import { useNavigation } from '@react-navigation/native'

const Header = ( { children } ) => {
	const navigation = useNavigation();
	
	return (
		<View style={ HeaderStyles.parentView }>
			<LinearGradientBackground color={ [ gradients.orange.from, gradients.orange.to ] } pattern={ LinearGradientBackgroundPatterns.RIGHT_TO_LEFT} />
			<ContentCentered>
				<View style={ HeaderStyles.contentView }>
					{ children }
				</View>
			</ContentCentered>
		</View>
	);
}

export default React.memo( Header, isEqual );