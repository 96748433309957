import { applyMiddleware, compose, createStore } from "redux";
import reducers from "services/redux/reducers";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import dataMiddleware from "services/redux/data/middleware";
import { getCache, setCache } from "services/redux/state-cache";
import _ from 'lodash';
import HelperDevelopment from "utils/helper/development";

const cache = getCache();

const store = createStore( reducers, !_.isEmpty( cache ) ? cache : composeWithDevTools(
	compose(
		applyMiddleware(
			dataMiddleware
		)
	)
) );

if( HelperDevelopment.reactIsInDevelopmentMode() ) {
	store.subscribe(() => setCache( store.getState() ) );
}

export default store;
