import React from "react";
import { Column as _Column, Container as _Container, Grid as _Grid, Row as _Row } from 'react-native-bootstrap-grid'
import _ from 'lodash'
import { BootstrapGridStyles } from 'styles/components/atoms/BootstrapGrid'
import HelperPlatform from 'utils/helper/platform'
import useDynamicStyle from 'utils/hooks/use-dynamic-style'

export const Grid = ( props ) => {
	let propStyle = _.get( props, 'style', {} );
	if( !_.isArray( propStyle ) ) {
		propStyle = [ propStyle ]
	}
	
	const style = [
		BootstrapGridStyles.grid,
		...propStyle
	]
	
	return (
		<_Grid
			{...props }
			style={ style }>
			
			{ props.children }
		</_Grid>
	)
}

export const Container = ( props ) => {
	let propStyle = _.get( props, 'style', {} );
	if( !_.isArray( propStyle ) ) {
		propStyle = [ propStyle ]
	}
	
	const style = useDynamicStyle([
		BootstrapGridStyles.container,
		...propStyle
	])
	
	return (
		<_Container
			{...props}
			style={ style }>
			
			{ props.children }
		</_Container>
	)
}

export const Row = ( props ) => {
	let propStyle = _.get( props, 'style', {} );
	if( !_.isArray( propStyle ) ) {
		propStyle = [ propStyle ]
	}
	
	const style = [
		BootstrapGridStyles.row,
		...propStyle
	]
	
	const align = _.get( props, 'align', false )
	if( align !== false ) {
		style.push({
			alignItems: align
		})
	}
	
	const justify = _.get( props, 'justify', false )
	if( justify !== false ) {
		style.push({
			justifyContent: justify
		})
	}
	
	const viewStyle = useDynamicStyle( style )
	
	return (
		<_Row
			{...props}
			style={ viewStyle }>
			
			{ props.children }
		</_Row>
	)
}

export const Column = ( props ) => {
	const style = [ BootstrapGridStyles.column, props.style ];
	
	const auto = _.get( props, 'auto', false )
	if( auto ) {
		style.push({
			width: 'auto',
			flex: HelperPlatform.isAndroid()  ? 0 : '0 0 auto',
		})
	}
	
	const noPadding = _.get( props, 'noPadding', false )
	if( noPadding ) {
		style.push({
			paddingHorizontal: 0
		})
	}
	
	const viewStyle = useDynamicStyle( style )
	
	return (
		<_Column
			{...props}
			style={ viewStyle }>
		
			{ props.children }
		</_Column>
	)
}


