export const dataConstants = {
	APP_LOADED: 'app_loaded',
	INITIAL_URL: 'initial_url',
	
	IS_SAVING: 'is_saving',
	
	CUSTOMER_STATISTICS: 'customer_statistics',
	CUSTOMER_STATISTICS_UPDATED_ATS: 'customer_statistics_updated_ats',
	
};
