import React from 'react'
import isEqual from 'react-fast-compare'
import { View } from 'react-native'
import { ModalStyles } from 'styles/components/organisms/Modal'
import Title from 'components/atoms/Title'
import ContentCentered from 'components/atoms/ContentCentered'
import Description from 'components/atoms/Description'
import { useSelector } from 'react-redux'
import { dataConstants } from 'services/redux/data/constants'
import { TitleSizeConstants } from 'constants/components/atoms/Title'
import { Column, Container, Grid, Row } from 'components/atoms/BootstrapGrid';

const Modal = () => {
	
	const props	= useSelector( state => state.data[ dataConstants.MODAL ] );
	
	if( _.get( props, 'visible', false ) === false ){
		return null;
	}
	
	let content = [];
	
	if( _.get( props, 'title', null ) !== null ){
		content.push( <Title size={ TitleSizeConstants.MEDIUM }>{ _.get( props, 'title', null ) }</Title> );
	}
	if( _.get( props, 'description', null ) !== null ){
		content.push( <Description>{ _.get( props, 'description', null ) }</Description> );
	}
	
	if( _.get( props, 'children', null ) !== null ){
		content.push( <View>{ props.children }</View> );
	}
	
	if( _.get( props, 'buttons', null ) !== null ){
		const buttonsFromProps = _.get( props, 'buttons', null );
		const buttons = [];
		
		_.forEach(  buttonsFromProps, ( button, index ) => {
			buttons.push(
				<Column key={ `button_${index}`} span={ {md: 12 / _.size( buttonsFromProps ), xs: 12 / _.size( buttonsFromProps ) } } style={ ModalStyles.buttonColumn}>
					{ button }
				</Column>
			);
		});
		
		content.push(
			<View style={Modal.buttonView}>
				<Grid>
					<Container>
						<Row>
							{ buttons }
						</Row>
					</Container>
				</Grid>
			</View>
		);
	}
	
	return (
		<View style={ ModalStyles.parentView }>
			<ContentCentered>
				<View style={ ModalStyles.contentView }>
					{ content }
				</View>
			</ContentCentered>
		</View>
	);
}

export default React.memo( Modal, isEqual );