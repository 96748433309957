import { StyleSheet } from 'react-native'

export const linearGradientBackgroundStyles = StyleSheet.create({
	container: {
		zIndex: -1,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0
	}
})
