import React from "react";
import { LinearGradient } from 'expo-linear-gradient'
import isEqual from 'react-fast-compare'
import { linearGradientBackgroundStyles } from 'styles/components/atoms/LinearGradientBackground'

export const LinearGradientBackgroundColors = {
	LIGHT: 'white',
	DARK: 'dark'
};

export const LinearGradientBackgroundPatterns = {
	TOP_TO_BOTTOM : 'top_to_bottom',
	BOTTOM_TO_TOP : 'bottom_to_top',
	CENTERED: 'centered',
	LEFT_TO_RIGHT: 'left_to_right',
	RIGHT_TO_LEFT: 'right_to_left'
}

const LinearGradientBackground = ( { color = LinearGradientBackgroundColors.LIGHT, pattern = LinearGradientBackgroundPatterns.TOP_TO_BOTTOM, maxOpacity = 0.3, minOpacity = 0 }) => {

	let colors = [];
	let start = null;
	let end = null;
	let r = 0;
	let g = 0;
	let b = 0;

	switch( color ){
		case LinearGradientBackgroundColors.LIGHT:
			r = g = b = 255;
            break;
        case LinearGradientBackgroundColors.DARK:
	        r = g = b = 0;
	        break;
		default:
		
	}
	
	switch( pattern ){
		case LinearGradientBackgroundPatterns.LEFT_TO_RIGHT:
			start = { x: 0, y: 0 };
			end = { x: 1, y: 0 };
			break;
		case LinearGradientBackgroundPatterns.RIGHT_TO_LEFT:
			start = { x: 1, y: 0 };
			end = { x: 0, y: 0 };
			break;
	}
	
	if( color === LinearGradientBackgroundColors.DARK || color === LinearGradientBackgroundColors.LIGHT ){
		switch( pattern ){
			case LinearGradientBackgroundPatterns.TOP_TO_BOTTOM:
				colors = [ `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${minOpacity} )`];
				break;
				
			case LinearGradientBackgroundPatterns.BOTTOM_TO_TOP:
				colors = [ `rgba( ${r}, ${g}, ${b}, ${minOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`];
				break;
				
			case LinearGradientBackgroundPatterns.CENTERED:
				colors = [ `rgba( ${r}, ${g}, ${b}, ${minOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${minOpacity} )`];
				break;
				
			case LinearGradientBackgroundPatterns.LEFT_TO_RIGHT:
				colors = [ `rgba( ${r}, ${g}, ${b}, ${minOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${maxOpacity} )`, `rgba( ${r}, ${g}, ${b}, ${minOpacity} )`];
				break;
		}
	}else{
		colors = color;
	}
	
	return (
		<LinearGradient
			colors={ colors }
			start={ start }
			end={ end }
			style={ linearGradientBackgroundStyles.container }
		/>
	);
}

export default React.memo( LinearGradientBackground, isEqual );
