export const TitleSizeConstants = {
  EXTRA_SMALL: 'extrasmall',
  SMALL: 'small',
  MEDIUM: 'medium',
  MEDIUM_LARGE: 'medium_large',
  LARGE: 'large',
  EXTRA_LARGE: 'extra_large',
  EXTRA_EXTRA_LARGE: 'extra_extra_large'
}

export const TitleColorConstants = {
  DARK: 'dark',
  LIGHT: 'light'
}