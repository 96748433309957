import { StyleSheet } from 'react-native'

export const PageBlockStyles = StyleSheet.create({
	row: {
		marginBottom: 30
	},
	column: {
		height: '100%',
		paddingHorizontal: 10,
		flexDirection: 'column',
	},
	columnFirst:{
		paddingLeft: 0
	},
	columnLast:{
		paddingRight: 0
	}
});
