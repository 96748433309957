import React from 'react'
import { ScrollView, StyleSheet, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context';
import { Restart } from 'fiction-expo-restart'
import i18n from 'utils/i18n'
import Title from 'components/atoms/Title'
import Description from 'components/atoms/Description'
import HelperVersion from 'utils/helper/version'
import HelperDevelopment from 'utils/helper/development'

//import * as Sentry from 'sentry-expo';

export class ErrorBoundary extends React.Component {
	
	state = {
		error: false,
		errorValue: '',
		errorInfo: {},
		errorState: {},
	}
	
	static getDerivedStateFromError( error ) {
		return {
			error: true,
			errorState: error
		};
	}
	
	componentDidCatch (error, errorInfo) {
		
		// Send to sentry
		//if( HelperPlatform.isWeb() ) {
		//	Sentry.Browser.captureException( error );
		//} else {
		//	Sentry.Native.captureException( error );
		//}
		
		// deal with errorInfo if needed
		this.setState({
			errorValue: error,
			errorInfo: errorInfo
		})
		
	}
	
	handleBackToSignIn = async () => {
		Restart();
	}
	
	render () {
		const { error, errorValue, errorInfo, errorState } = this.state;
		
		if( error ) {
			return (
				<SafeAreaView>
					<ScrollView>
						<View style={ styles.container }>
							<Title>{ i18n.t('error.global.title') }</Title>
							<Description>{ i18n.t('error.global.text') }</Description>
						</View>
						
						<If condition={ HelperVersion.isTestVersion() || HelperDevelopment.reactIsInDevelopmentMode() }>
							<View>
								<Description>
									{ JSON.stringify( errorValue ) }
								</Description>
								<Description>
									{ JSON.stringify( errorInfo ) }
								</Description>
								<Description>
									{ JSON.stringify( errorState ) }
								</Description>
							</View>
						</If>
					</ScrollView>
				</SafeAreaView>
			)
		}
		
		return this.props.children;
	}
}

export const styles = StyleSheet.create( {
	headerImage: {
		height: 75,
		width: '100%',
		marginBottom: 20
	}
})

export default ErrorBoundary;
