import { StyleSheet } from 'react-native'
import { borderRadius, colors, shadow } from 'styles/App';

export const StatisticStyle = StyleSheet.create({
	parentView: {
		flex: 1,
		minHeight: 230
	},
	parentViewSmall: {
		flex: 1,
		minHeight: 100
	},
	blockView: {
		zIndex: 0,
		flex: 1,
		flexDirection: 'column',
		marginVertical: 0,
		marginHorizontal: 0
	},
	blockContentView: {
		flex: 1
	},
	childrenContentView:{
		flex: 1,
	},
	iconContentView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		zIndex: -1,
		overflow: 'hidden',
		justifyContent: 'flex-end',
		padding: 20,
		opacity: 0.08
	},
	icon:{
		fontSize: 100,
		color: colors.primary.primary,
		alignSelf: 'flex-end',
	},
	iconSmall:{
		fontSize: 50,
		color: colors.primary.primary,
		alignSelf: 'flex-end',
	}
});