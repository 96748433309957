import React from 'react'
import isEqual from 'react-fast-compare'
import Block from 'components/atoms/Block'
import { Image, View } from 'react-native'
import { StatisticStyle } from 'styles/components/organisms/Statistic'
import Title from 'components/atoms/Title'
import i18n from "utils/i18n";
import _ from "lodash";
import { useSelector } from 'react-redux'
import { dataConstants } from 'services/redux/data/constants'
import { TitleSizeConstants } from 'constants/components/atoms/Title'
import Icon from 'components/atoms/Icon'
import { IconSystemConstants } from 'constants/IconNameConstants'

const Statistic = ( { size = 'regular', customer_id = null, statistic = null, iconName = null, iconColor = null, titleSize = TitleSizeConstants.LARGE, statisticSize = TitleSizeConstants.EXTRA_EXTRA_LARGE, children } ) => {
	let content = [];
	
	const customerStatistics = useSelector( state => state.data[ dataConstants.CUSTOMER_STATISTICS ] )
	
	let statisticsValue = _.get( customerStatistics, [ customer_id, statistic ], '...' );
	
	if( statisticsValue === null ){
		statisticsValue = '-';
	}
	
	content.push(
		<>
			<Title size={ titleSize } style={{minHeight: 70}}>{ i18n.t( `statistics.${ statistic }`) }</Title>
			<Title size={ statisticSize } style={ {margin: 0}}>{ statisticsValue }</Title>
		</>
	);
	
	
	let parentViewStyle = StatisticStyle.parentView;
	let iconStyle = StatisticStyle.icon;
	
	if( size === 'small' ){
		parentViewStyle = StatisticStyle.parentViewSmall;
		iconStyle = StatisticStyle.iconSmall;
	}
	
	return (
		<View style={ parentViewStyle }>
			<Block style={ StatisticStyle.blockView }>
				<View style={ StatisticStyle.iconContentView }>
					<Icon icon={ iconName } style={ [ iconStyle, { color: iconColor } ] } />
				</View>
				<View style={ StatisticStyle.blockContentView }>
					{ content }
				</View>
			</Block>
		</View>
	);
}

export default React.memo( Statistic, isEqual );