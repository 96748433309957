import React from 'react'
import isEqual from 'react-fast-compare'
import { Text } from 'react-native'
import { Fonts } from 'styles/App'
import { DescriptionColorConstants } from 'constants/components/atoms/Description'
import { TitleColorConstants } from 'constants/components/atoms/Title'

const Description = ( { centered = undefined, strong = undefined, color = DescriptionColorConstants.DARK, children} ) => {
	
	let styles =  [ Fonts.description ];
	
	if( centered !== undefined && centered !== false ) {
		styles.push( Fonts.centered );
	}
	if( strong !== undefined && strong !== false ) {
		styles.push( Fonts.strong );
	}
	
	switch( color ) {
		case TitleColorConstants.DARK:
			styles.push( Fonts.textColorDark );
			break;
		case TitleColorConstants.LIGHT:
			styles.push( Fonts.textColorLight );
			break;
	}
	
	return (
		<Text style={ styles }>
			{children}
		</Text>
	);
}

export default React.memo( Description, isEqual );