import React, { useEffect, useState } from "react";
import Page from 'components/organisms/Page'
import Title from 'components/atoms/Title'
import PageBlock from 'components/molecules/PageBlock'
import { useNavigation, useRoute } from '@react-navigation/native'
import Statistic from 'components/organisms/Statistic'
import Header from 'components/molecules/Header'
import { TitleColorConstants, TitleSizeConstants } from 'constants/components/atoms/Title'
import FetchStatistics from 'utils/fetch/Statistics'
import { Customers } from 'constants/Customers'
import { Statistics } from 'constants/Statistics'
import { CountdownCircleTimer } from 'react-native-countdown-circle-timer'
import {Pressable, Text, View} from 'react-native'
import { colors, gradients } from 'styles/App'
import { IconSystemConstants, IconTimeslotConstants } from 'constants/IconNameConstants'
import {DashboardIndexStyles} from "styles";
import LinearGradientBackground, {LinearGradientBackgroundPatterns} from "components/atoms/LinearGradientBackground";

const SceneHomeDashboardIndex = () => {

	const [ customerRotationTime, setCustomerRotationTime ] = useState( 20 );

	const [ isFirstRun, setIsFirstRun ] = useState( true );
	const [ currentCustomer, setCurrentCustomer ] = useState( null );
	const [ currentCustomerIndex, setCurrentCustomerIndex ] = useState( 0 );
	
	
	useEffect( () => {
		FetchStatistics.fetchCustomers( Customers );
	}, [] );
	
	useEffect( () => {
		setCurrentCustomer( Customers[ currentCustomerIndex ] );
	}, [ currentCustomerIndex ] );
	
	useEffect( () => {
		if( isFirstRun === false && currentCustomer !== null ){
			FetchStatistics.fetchCustomer( currentCustomer.endpoint );
		}
	}, [ isFirstRun, currentCustomer ]);
	
	const showNextCustomer = () => {
		// get the next customer index from Customers
		let nextCustomerIndex = currentCustomerIndex + 1;
		if( nextCustomerIndex >= Customers.length ){
			nextCustomerIndex = 0;
			setIsFirstRun( false );
		}

		setCustomerRotationTime( 20 );
		setCurrentCustomerIndex( nextCustomerIndex );
	}

	const showCustomer = ( customer ) => {
		setCustomerRotationTime( 60 );
		setCurrentCustomer( customer );
	}

	let customerButtons = [];

	_.forEach( Customers, ( customer ) => {
		let buttonStyles = [ DashboardIndexStyles.customerButtonsButton ];
		let textStyles = [ DashboardIndexStyles.customerButtonsButtonText ];
		let isCurrentCustomer = false;
		if( currentCustomer !== null && customer.endpoint === currentCustomer.endpoint ){
			buttonStyles.push( DashboardIndexStyles.customerButtonsButtonActive );
			textStyles.push( DashboardIndexStyles.customerButtonsButtonActiveText );
			isCurrentCustomer = true;
		}

		customerButtons.push(
			<Pressable onPress={ showCustomer.bind( this, customer ) }>
				<View style={ buttonStyles }>
					<Text style={ textStyles }>{customer.name}</Text>
					<If condition={ isCurrentCustomer }>
						<View style={ { position: 'absolute', left: 10, top: 15,backgroundColor: colors.base.light, borderRadius: 20}}>
							<CountdownCircleTimer
								key={ `${ currentCustomer.endpoint }` }
								isPlaying
								duration={ customerRotationTime }
								colors={[ gradients.orange.from, gradients.orange.to ]}
								colorsTime={[customerRotationTime, 0]}
								size={ 30 }
								strokeWidth={ 4 }
								onComplete={ showNextCustomer }
							/>
						</View>
					</If>
				</View>
			</Pressable>
		)
	});

	return (
		<>
			<View style={{flexDirection: 'row', height: '100%'}}>
				<View style={ DashboardIndexStyles.customerButtons }>
					<LinearGradientBackground color={ [ gradients.orange.to, gradients.orange.from ] } pattern={ LinearGradientBackgroundPatterns.BOTTOM_TO_TOP} />
					{ customerButtons }
				</View>
				<View style={{flex:9}}>
					<Page>
						<PageBlock columnsPerRow={ 4 }>
							<If condition={ currentCustomer }>
								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.ACTIVE_USERS } iconName={ IconSystemConstants.PERSON } />
								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.ACTIVE_USERS_TODAY } iconName={ IconSystemConstants.MOBILE_PHONE  } />
								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.BOOKINGS_MADE_TODAY } iconName={ IconSystemConstants.CALENDAR } />
								<View />

								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.CHECKINS_FOR_TODAY } iconName={ IconSystemConstants.QRCODE } />
								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.EMPLOYEES_WITH_BOOKINGS_FOR_TODAY } iconName={ IconTimeslotConstants.WORKPLACE }  iconColor={ colors.primary.dark } />
								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.EMPLOYEES_WITH_LUNCHES_FOR_TODAY } iconName={ IconTimeslotConstants.LUNCH }  iconColor={ colors.blue.dark }/>
								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.EMPLOYEES_WITH_TRANSPORTS_FOR_TODAY } iconName={ IconTimeslotConstants.PARKING } />

								<Statistic customer_id={ currentCustomer.endpoint } statistic={ Statistics.RESPONSE_TIME_TODAY } iconName={ IconSystemConstants.CLOCK } statisticSize={ TitleSizeConstants.EXTRA_LARGE} size={ 'small' } />
								<View />
								<View />
								<View />
							</If>
						</PageBlock>
					</Page>
				</View>
			</View>
		</>
	);
}

export default SceneHomeDashboardIndex;