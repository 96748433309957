import { StyleSheet } from 'react-native'

export const BootstrapGridStyles = StyleSheet.create({
	grid: {
		width: '100%',
		maxWidth: '100%',
		paddingHorizontal: 0,
		marginHorizontal: 0
	},
	
	container: {
		width: '100%',
		maxWidth: '100%',
		paddingHorizontal: 0,
		paddingVertical: 0,
		marginHorizontal: 0,
		marginVertical: 0
	},
	
	row: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingHorizontal: 0,
		paddingVertical: 0,
		marginHorizontal: 0,
		marginVertical: 0
	},
	
	column: {
		justifyContent: 'flex-start'
	}
});
