import React, { useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import LinearGradientBackground, { LinearGradientBackgroundPatterns } from 'components/atoms/LinearGradientBackground'
import { gradients } from 'styles/App';
import * as Animatable from 'react-native-animatable'
import { LoaderStyles, parentViewRight } from 'styles/components/organisms/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { dataConstants } from 'services/redux/data/constants'
import axios from 'axios'
import { setData } from 'services/redux/data/actions'

const Loader = ( props ) => {
	const animateRef = useRef();
	const dispatch = useDispatch()
	
	const [ loadingCalls, setLoadingCalls ] = useState( [] );
	const [ hideTimeout, setHideTimeout ] = useState( null );
	
	const [ currentVisibility, setCurrentVisibility ] = useState( true );
	const [ visible, setVisible ] = useState( false );
	const [ currentAnimation, setCurrentAnimation ] = useState( null );
	const [ currentAnimationName, setCurrentAnimationName ] = useState( null );
	
	let parentViewStyles = [ LoaderStyles.parentView ];
	
	useEffect( () => {
		dispatch( setData( dataConstants.IS_SAVING, _.size( loadingCalls ) > 0 ) );
	}, [ loadingCalls ] );
	
	const animationFadeIn = {
		0: { opacity: 0, right: '-5rem'},
		1: { opacity: 1, right: parentViewRight }
	};

	const animationFadeOut = {
		0: { opacity: 1, right: parentViewRight },
		1: { opacity: 0, right: '-5rem' }
	};
	
	useEffect( () => {
		if( visible !== currentVisibility ){
			clearTimeout( hideTimeout );
			if( visible ){
				if( currentAnimationName !== 'fadeIn' ){
					setCurrentAnimationName( 'fadeIn' );
					setCurrentAnimation( animationFadeIn );
				}
			}else{
				// we add a small delay to ensure that the user actually sees this :O
				setHideTimeout( setTimeout( () => {
					if( currentAnimationName !== 'fadeOut' ) {
						setCurrentAnimationName( 'fadeOut' );
						setCurrentAnimation( animationFadeOut );
					}
				}, 500 ) );
			}
			setCurrentVisibility( visible );
		}

	}, [ visible ]);
	
	useEffect( () => {
		if( ( loadingCalls.length > 0 ) !== visible ) {
			setVisible( ( loadingCalls.length > 0 ) );
		}
	}, [ loadingCalls ] );
	
	useEffect( () => {
		axios.interceptors.request.use(request => {
			// get X-Requested-ID value from request
			let requestID = _.get( request, [ 'headers', 'X-Requested-ID'], null );
			if( requestID ){
				setLoadingCalls( [ ...loadingCalls, ...[ requestID ] ] );
			}
			return request;
		});
		
		axios.interceptors.response.use(response => {
			let requestID = _.get( response, [ 'config', 'headers', 'X-Requested-ID'], null );
			if( requestID !== null ){
				let newLoadingCalls = _.filter( loadingCalls, ( call ) => {
					return call !== requestID;
				});
				setLoadingCalls( newLoadingCalls );
			}
			return response;
		});
	}, [] );
	
	return (
		<Animatable.View animation={ currentAnimation }  style={ parentViewStyles }>
			<LinearGradientBackground color={ [ gradients.background.to, gradients.background.from ] } pattern={ LinearGradientBackgroundPatterns.BOTTOM_TO_TOP} />
		</Animatable.View>
	);
}

export default React.memo( Loader, isEqual );