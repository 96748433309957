export const Statistics = {
	ACTIVE_USERS: 'active_users',
	ACTIVE_USERS_TODAY: 'active_users_today',
	CHECKINS_FOR_TODAY: 'checkins_for_today',
	EMPLOYEES_WITH_BOOKINGS_FOR_TODAY: 'employee_with_bookings_for_today',
	EMPLOYEES_WITH_TRANSPORTS_FOR_TODAY: 'employee_with_transports_for_today',
	EMPLOYEES_WITH_LUNCHES_FOR_TODAY: 'employee_with_lunches_for_today',
	
	BOOKINGS_MADE_TODAY: 'bookings_made_today',
	BOOKINGS_FOR_TODAY: 'bookings_for_today',
	TRANSPORTS_FOR_TODAY: 'transports_for_today',
	LUNCHES_FOR_TODAY: 'lunches_for_today',

	RESPONSE_TIME_TODAY: 'response_time_today'

}