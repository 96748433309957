import { useEffect, useState } from 'react'
import { StyleSheet } from "react-native";
import isEqual from 'react-fast-compare'
import _ from 'lodash'
import HelperStyles from 'utils/helper/styles'

const createStyleSheet = style => {
	if( _.isArray( style ) ) {
		style = HelperStyles.toObject( style );
	}
	
	return StyleSheet.create( { style: style } ).style
}

const useDynamicStyle = ( style ) => {
	const [ currentStyle, setCurrentStyle ] = useState( style );
	const [ stylesheet, setStylesheet ] = useState( createStyleSheet( style ) );
	
	useEffect(() => {
		if( isEqual( style, currentStyle ) ) return;
		
		setCurrentStyle( style );
		setStylesheet( createStyleSheet( style ) );
	}, [ style ] );
	
	return stylesheet;
}

export default useDynamicStyle
