import * as Localization from 'expo-localization';
import _ from 'lodash';

export default class HelperLocale {

	static getDeviceLocale() {
		let deviceLocale = Localization.locale;
		
		switch( true ) {
			case _.startsWith( deviceLocale, 'en-' ):
				deviceLocale = 'en-GB';
				break;
				
			case _.startsWith( deviceLocale, 'nl-' ):
				deviceLocale = 'nl-NL';
				break;
				
			default:
				deviceLocale = 'nl-NL';
		}
		
		return deviceLocale;
	}
	
	static localeSubstr( lng ) {
		return lng.substr( 0, lng.indexOf('-') );
	}
	
	static log( value ) {
		console.prefix('helperLocale', value );
	}
}
