import React from 'react'
import { renderFontAwesomeIcon, renderMaterialCommunityIcon } from 'components/atoms/Icons'
import { IconConstants, IconFonts, IconSystemConstants } from 'constants/IconNameConstants'
import isEqual from 'react-fast-compare'

const Icon = ( props ) => {
	let iconData = IconConstants[ props.icon ];
	
	if( iconData === undefined ){
		iconData = IconConstants[ IconSystemConstants.ERROR ];
	}
	
	if( iconData.function ){
		return iconData.function( props );
	}
	
	switch( iconData.font ){
		case IconFonts.FONTAWESOME:
			return renderFontAwesomeIcon( iconData.name, props, iconData.fontStyle )
		case IconFonts.MATERIAL_COMMUNITY:
			return renderMaterialCommunityIcon( iconData.name, props );
	}
	
	return renderFontAwesomeIcon( IconConstants[ IconSystemConstants.ERROR ].name, props );
}

export default React.memo( Icon, isEqual );