import React from "react";
import axios from "axios";

import store from "services/redux/store";
import { dataConstants } from 'services/redux/data/constants'
import _ from 'lodash'
import { setData, setDataMulti } from 'services/redux/data/actions'

export default class FetchStatistics {
	
	static createUrl( endpoint, date = null ) {
		let url = `https://server-42.werktopkantoor.nl/${endpoint}/dashboard/generate_live_statistics`;

		if( date !== null ) {
			url = `${ url }/date/{date}`;
		}
		
		return url;
	}
	
	static fetchCustomer( customer_id) {
		let statisticsCustomersUpdatedAts = store.getState().data[ dataConstants.CUSTOMER_STATISTICS_UPDATED_ATS ];
		
		const customerUpdatedAt = _.get( statisticsCustomersUpdatedAts, [ customer_id ], null );
		// only run code if default value is not null or date is more than 5 minutes ago
		if( customerUpdatedAt === null || ( new Date() - customerUpdatedAt ) > 60 * 5 * 1000 ) {
			const axiosClient = axios.create( {
				baseURL: this.createUrl( customer_id ),
				headers: {
					//Authorization: null
				}
			} );
			
			axiosClient.get()
				.then( response => {
					let statistics = store.getState().data[ dataConstants.CUSTOMER_STATISTICS ];
					statistics = { ...statistics, ...{ [ customer_id ]: response.data } };
					statisticsCustomersUpdatedAts = { ...statisticsCustomersUpdatedAts, ...{ [ customer_id ]: new Date() } };
					
					store.dispatch( setDataMulti( {
						[ dataConstants.CUSTOMER_STATISTICS ]: statistics,
						[ dataConstants.CUSTOMER_STATISTICS_UPDATED_ATS ]: statisticsCustomersUpdatedAts
					}));
			});
		}
	}
	
	static fetchCustomers( customers ){
		customers.forEach( customer => {
			this.fetchCustomer( customer.endpoint );
		} );
	}
	
}
