import { decode, encode } from 'base-64';

import 'core-js/features/string/replace-all';

if (!global.btoa) {
	global.btoa = encode;
}

if (!global.atob) {
	global.atob = decode;
}
