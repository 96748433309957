import { StyleSheet } from 'react-native'

export const PageBlockViewStyles = StyleSheet.create({
	view: {
		flexDirection: 'column',
		height: '100%'
	}
});




