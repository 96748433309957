import { StyleSheet } from 'react-native'

const headerHeight = '4rem';

export const HeaderStyles = StyleSheet.create({
	parentView: {
		position: 'relative',
		alignItems: 'center',
		justifyContent: 'center',
		height: headerHeight
	},
	
	contentView: {
		alignItems: 'center',
		justifyContent: 'center'
	},
});
