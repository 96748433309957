import React from 'react'
import isEqual from 'react-fast-compare'
import { Column, Container, Grid, Row } from 'components/atoms/BootstrapGrid'
import { PageBlockStyles } from 'styles/components/molecules/PageBlock'
import PageBlockView from 'components/atoms/PageBlockView'

const PageBlock = ( { columnsPerRow = 3, children } ) => {
	
	let columnSize = 12 / columnsPerRow;
	
	let rows = [];
	let columns = [];
	
	if( _.size( children ) > 0 ){
		for( let i = 0; i < children.length; i++ ) {
			
			let columnStyles = [ PageBlockStyles.column ];
			
			if( columns.length === 0 ){
				columnStyles.push( PageBlockStyles.columnFirst );
			}
			
			if( columns.length + 1 === columnsPerRow ){
				columnStyles.push( PageBlockStyles.columnLast );
			}
			
			let child = children[ i ];
			columns.push(
				<Column key={ `column_${ i }` } span={{ xs: columnSize, md: columnSize }} style={ columnStyles }>
					<PageBlockView>
						{ child }
					</PageBlockView>
				</Column>
			);
			
			if( columns.length === columnsPerRow ) {
				rows.push(
					<Row justify={'center'} align={'center'} style={ PageBlockStyles.row }>
						{ columns }
					</Row>
				);
				columns = [];
			}
		}
		
		if( columns.length > 0 ) {
			rows.push(
				<Row justify={'center'} align={'center'}  style={ PageBlockStyles.row }>
					{ columns }
				</Row>
			);
		}
	}
	
	return (
		<Grid>
			<Container>
				{ rows }
			</Container>
		</Grid>
	);
}

export default React.memo( PageBlock, isEqual );