import { StyleSheet } from 'react-native'
import { borderRadius } from 'styles/App'

export const ModalStyles = StyleSheet.create({
	parentView: {
		zIndex: 1000,
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		backgroundColor: 'rgba( 0, 0, 0, 0.3 )',
		alignItems: 'center',
		justifyContent: 'center'
	},
	contentView: {
		alignSelf: 'center',
		width: '50%',
		backgroundColor: '#fff',
		borderRadius: borderRadius.primary,
		padding: '1rem'
	},
	buttonView: {
		marginTop: '1rem',
	},
	buttonColumn: {
		alignItems: 'center'
	}
});
