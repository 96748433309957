import HelperDevelopment from "utils/helper/development";

if( !HelperDevelopment.reactIsInDevelopmentMode() ) {
	console.log = () => null;
	console.time = () => null;
	console.timeEnd = () => null;
	console.prefix = ( prefix = '' ) => null;
}else{
	var originalConsoleLog = console.log;
		console.prefix = function( prefix = '' ) {
		let args = [];

		args.push('[MOD]');

		if ( prefix.length > 0 ) {
			const today = new Date();
			const time = String(today.getHours()).padStart(2, '0') + ":" + String(today.getMinutes()).padStart(2, '0') + ":" + String(today.getSeconds()).padStart(2, '0');
			args.push( '[' + prefix + '][' + time + '] ' );
		}

		// Note: arguments is part of the prototype
		for ( let i = 1; i < arguments.length; i++ ) {
			args.push( arguments[ i ] );
		}

		originalConsoleLog.apply( console, args );
	};
	
	if( console.time === undefined ){
		console.time = () => null;
	}
	
	if( console.timeEnd === undefined ){
		console.timeEnd = () => null;
	}
}