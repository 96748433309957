import React from 'react'
import isEqual from 'react-fast-compare'
import { Text } from 'react-native'
import { Fonts } from 'styles/App'
import { TitleColorConstants, TitleSizeConstants } from 'constants/components/atoms/Title'

const Title = ( { centered = undefined, size = TitleSizeConstants.LARGE, color = TitleColorConstants.DARK, noMargins = false, style = null, children } ) => {
	let styles =  [ Fonts.title ];
	
	if( centered !== undefined && centered !== false ) {
		styles.push( Fonts.centered );
	}
	
	switch( size ) {
		case TitleSizeConstants.EXTRA_SMALL:
			styles.push( Fonts.fontSizeExtraSmall );
			break;
		case TitleSizeConstants.SMALL:
			styles.push( Fonts.fontSizeSmall );
			break;
		case TitleSizeConstants.MEDIUM:
			styles.push( Fonts.fontSizeMedium );
			break;
		case TitleSizeConstants.MEDIUM_LARGE:
			styles.push( Fonts.fontSizeMediumLarge );
			break;
		case TitleSizeConstants.LARGE:
			styles.push( Fonts.fontSizeLarge );
			break;
		case TitleSizeConstants.EXTRA_LARGE:
			styles.push( Fonts.fontSizeExtraLarge );
			break;
		case TitleSizeConstants.EXTRA_EXTRA_LARGE:
			styles.push( Fonts.fontSizeExtraExtraLarge );
			break;
	}
	
	switch( color ) {
		case TitleColorConstants.DARK:
			styles.push( Fonts.textColorDark );
			break;
		case TitleColorConstants.LIGHT:
			styles.push( Fonts.textColorLight );
			break;
	}
	
	if( noMargins ){
		styles.push( Fonts.noMargins );
	}
	
	if( style ){
		styles.push( style );
	}
	
	return (
		<Text style={ styles }>
			{children}
		</Text>
	);
}

export default React.memo( Title, isEqual );