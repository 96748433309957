export const Customers = [
	{
		endpoint: 'pathe',
		name: 'Pathe'
	},
	{
		endpoint: 'knab',
		name: 'Knab'
	},
	{
		endpoint: 'fremantle',
		name: 'Fremantle'
	},
	{
		endpoint: 'rechtspraak',
		name: 'Bureau van de rechtspraak'
	},
	{
		endpoint: 'studytube',
		name: 'StudyTube'
	},
	{
		endpoint: 'otrium',
		name: 'Otrium'
	},
	{
		endpoint: 'grant_thornton',
		name: 'Grant Thornton'
	},
	{
		endpoint: 'hunkemoller',
		name: 'Hunkemoller'
	},
	{
		endpoint: 'stern',
		name: 'Stern'
	},
	//{
	//	endpoint: 'rituals',
	//	name: 'Rituals (NL)'
	//},
	//{
	//	endpoint: 'rituals_be',
	//	name: 'Rituals (BE)'
	//},
	//{
	//	endpoint: 'rituals_ge',
	//	name: 'Rituals (DE/CH)'
	//},
	//{
	//	endpoint: 'rituals_se',
	//	name: 'Rituals (SE)'
	//},
	//{
	//	endpoint: 'rituals_uk',
	//	name: 'Rituals (UK)'
	//}
];