import "./wdyr";
import React, { Suspense, useEffect, useState } from 'react';
import 'components/polyfills';
import 'utils/console-prefix';
import { enableScreens } from 'react-native-screens';
import { initialWindowMetrics, SafeAreaProvider } from 'react-native-safe-area-context';
import 'react-native-gesture-handler';
import 'moment/locale/nl';
import 'moment/locale/en-gb';
import AppLoading from 'expo-app-loading';
import { Provider, useDispatch } from 'react-redux';
import * as Font from 'expo-font';
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons';
import store from "services/redux/store";
import * as SplashScreen from 'expo-splash-screen';
import 'utils/sentry';
import HelperPlatform from "utils/helper/platform";
import i18n from "utils/i18n";
import HelperLocale from 'utils/helper/locale'
import Moment from "moment";
import { Dimensions, LogBox, Text } from 'react-native'
import 'styles/imports'
import ErrorBoundary from 'components/atoms/ErrorBoundary'
import SceneIndex from 'scenes'
enableScreens();

function cacheFonts(fonts) {
	return fonts.map(font => {
		return Font.loadAsync(font)
	});
}

// catch the event and make changes accordingly
i18n.on('languageChanged', function(lng) {
	// E.g. set the moment locale with the current language
	const localeCode = HelperLocale.localeSubstr( lng );

	// Change moment
	Moment.locale( localeCode );
});

const window = Dimensions.get("window");
const screen = Dimensions.get("screen");

const App = () => {
	const [ loaded, setLoaded ] = useState( false )
	const [ dimensions, setDimensions ] = useState({ window, screen });
	
	
	const onChangeDimensions = ({ window, screen }) => {
		setDimensions({ window, screen });
	}
	
	const loadAssetsAsync = async () => {
		const fontAssets = cacheFonts([
			MaterialCommunityIcons.font,
			FontAwesome5.font,
			{
				'HeinekenItalic': require( 'assets/fonts/6beb7e3541b54d847effb05e119eeb4c.woff'),
				'HeinekenBold': require( 'assets/fonts/7da3d8955eb76733f2a73621332df5da.woff'),
				'HeinekenBoldItalic': require( 'assets/fonts/010f7ed7bd90604c16499d6afa087e7c.woff'),
				'Heineken': require( 'assets/fonts/83d2e7e2226dee3d9e90572a7473ae59.woff')
			}
		]);
		
		await Promise.all([
			...fontAssets
		]);
	}
	
	useEffect(() => {
		Dimensions.addEventListener( "change", onChangeDimensions );
		
		return () => Dimensions.removeEventListener( 'change', onChangeDimensions );
	}, [] );
	
	useEffect(() => {
		if( HelperPlatform.isNotWeb() ) {
			LogBox.install();
			LogBox.ignoreAllLogs( true );
		}
		
		// Prevent native splash screen from autohiding
		try {
			SplashScreen.preventAutoHideAsync();
		} catch (e) {
			console.warn(e);
		}
	}, [] )
	
	if( !loaded ) {
		return (
			<AppLoading
				startAsync={ loadAssetsAsync }
				onFinish={ () => setLoaded( true ) }
				onError={ console.warn }
			/>
		)
	}
	
	let content = ( <SceneIndex /> );
	
	return (
		<Suspense >
			<Provider store={ store }>
				<SafeAreaProvider initialMetrics={ initialWindowMetrics }>
					<ErrorBoundary>
						{ content }
					</ErrorBoundary>
				</SafeAreaProvider>
			</Provider>
		</Suspense>
	);
}

export default App
