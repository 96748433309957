// Global actions
import { ADD_DATA, ADD_DATA_MULTI, RESET_DATA, SET_DATA, SET_DATA_MULTI } from "services/redux/action-types";
import { dataConstants } from "services/redux/data/constants";
import HelperObject from "utils/helper/object";
import _ from 'lodash';

export function setData( key, payload ) {
	return {
		type: SET_DATA,
		key: key,
		payload
	}
}

export function addData( key, payload, resetKeys = false ) {
	return {
		type: ADD_DATA,
		key: key,
		payload,
		payload_reset_by_keys: resetKeys
	}
}

export function setDataMulti( values = {} ) {
	return {
		type: SET_DATA_MULTI,
		payload: values
	}
}

export function addDataMulti( values = {} ) {
	return {
		type: ADD_DATA_MULTI,
		payload: values
	}
}

export function setDataByGuid( key, values = {} ) {
	const data = HelperObject.groupByGuid( values );
	
	return {
		type: SET_DATA,
		key: key,
		payload: data
	}
}

export function addDataByGuid( key, values = {} ) {
	const data = HelperObject.groupByGuid( values );
	
	return {
		type: ADD_DATA,
		key: key,
		payload: data
	}
}

export function setDataMultiByGuid( values = {} ) {
	const data = {};
	_.forEach( values, ( value, key ) => {
		data[ key ] = HelperObject.groupByGuid( value );
	} );
	
	return {
		type: SET_DATA_MULTI,
		payload: data
	}
}

export function addDataMultiByGuid( values = {} ) {
	const data = {};
	_.forEach( values, ( value, key ) => {
		data[ key ] = HelperObject.groupByGuid( value );
	} );
	
	return {
		type: ADD_DATA_MULTI,
		payload: data
	}
}

export function resetData() {
	return {
		type: RESET_DATA
	}
}

export function removeSettings() {
	return {
		type: SET_DATA,
		key: dataConstants.SETTINGS,
		payload: {}
	}
}

export function setSettings( settings = [] ) {
	const payload = {};
	
	_.forEach( settings, setting => {
		payload[ setting.name ] = setting.value;
	})
	
	return {
		type: ADD_DATA,
		key: dataConstants.SETTINGS,
		payload
	}
}
