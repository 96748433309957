import { ADD_DATA, SET_DATA } from "services/redux/action-types";

const dataMiddleware = store => next => action => {
	switch( action.type ) {
		case SET_DATA: // just leave this here for later
			return next( action );
		case ADD_DATA: // just leave this here for later
			return next( action );
		default:
			return next(action);
	}
};

export default dataMiddleware;
