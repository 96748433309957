// Data
export const SET_DATA = "SET_DATA";
export const SET_DATA_MULTI = "SET_DATA_MULTI";
export const ADD_DATA = "ADD_DATA";
export const ADD_DATA_MULTI = "ADD_DATA_MULTI";
export const RESET_DATA_KEY = "RESET_DATA_KEY";
export const REMOVE_DATA_KEY = "REMOVE_DATA_KEY";
export const REMOVE_DATA_ARRAY_ITEM = "REMOVE_DATA_ARRAY_ITEM";

// Other
export const RESET_DATA = "RESET_DATA";
