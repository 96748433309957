import React from "react";
import { View } from 'react-native'
import HomeNavigator from 'navigations/Home'

const SceneIndex = () => {
	let content = [];
	
	
	content.push(
		<HomeNavigator />
	);
	
	return (
		<View style={{flex: 1, height: '100%'}}>
			{content}
		</View>
	);
};

export default SceneIndex;