import {colors} from "styles/App";

export const DashboardIndexStyles = {
	customerButtons: {
		flex: 2,
		backgroundColor: colors.primary.dark,

		borderRightWidth: 1,
		borderRightColor: colors.grey.primary,
	},

	customerButtonsButton: {
		paddingVertical: 20,
		paddingLeft: 50,
		alignItems: 'left',
	},
		customerButtonsButtonText: {
			fontWeight: '800',
			fontSize: 16,
			color: colors.base.light
		},

	customerButtonsButtonActive: {
		backgroundColor: colors.base.light
	},

		customerButtonsButtonActiveText: {
			fontWeight: '800',
			color: colors.primary.primary
		}
}