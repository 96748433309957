import React, { useEffect, useRef, useState } from 'react'
import isEqual from 'react-fast-compare'
import { ScrollView, View } from 'react-native'
import LinearGradientBackground, { LinearGradientBackgroundPatterns } from 'components/atoms/LinearGradientBackground'
import { gradients } from 'styles/App';
import ContentCentered from 'components/atoms/ContentCentered'
import { PageStyles } from 'styles/components/organisms/Page'
import { useDispatch, useSelector } from 'react-redux'
import { dataConstants } from 'services/redux/data/constants'
import { setData } from 'services/redux/data/actions'
import { useIsFocused } from '@react-navigation/native'

const Page = ( { scrollPositionIdentifier = null, children } ) => {
	const dispatch = useDispatch()
	const isFocused = useIsFocused();
	
	const _scrollPositionData = useSelector( state => state.data[ dataConstants.SCROLL_POSITION_DATA ] );
	const [ scrollViewLoaded, setScrollViewLoaded ] = useState( false );
	const [ scrollViewPosition, setScrollViewPosition ] = useState( 0 );
	const scrollViewRef = useRef( null );
	
	const onScroll = ( event ) => {
		if( isFocused && scrollPositionIdentifier && scrollViewLoaded ){
			dispatch( setData( dataConstants.SCROLL_POSITION_DATA, {..._scrollPositionData, ...{ [ scrollPositionIdentifier ] : event.nativeEvent.contentOffset.y } }) );
		}
	}
	
	useEffect( () => {
		if( scrollPositionIdentifier && scrollViewRef.current !== null){
			if(  _.get( _scrollPositionData, scrollPositionIdentifier, 0 ) !== 0 ){
				setInterval( () => {
					scrollViewRef.current?.scrollTo( { y: _.get( _scrollPositionData, scrollPositionIdentifier, 0 ), animated: false } );
				}, 500 );
				
			}
			setScrollViewLoaded( true );
		}
	}, [ scrollViewRef, isFocused ] );
	
	
	return (
		<View style={ PageStyles.parentView }>
			<LinearGradientBackground color={ [ gradients.background.to, gradients.background.from ] } pattern={ LinearGradientBackgroundPatterns.BOTTOM_TO_TOP} />
			<ScrollView ref={ scrollViewRef } onScroll={ onScroll } style={ PageStyles.scrollView }  scrollEventThrottle={ 500 }>
				<ContentCentered>
					{ children }
				</ContentCentered>
			</ScrollView>
		</View>
	);
}

export default React.memo( Page, isEqual );