import { StyleSheet } from 'react-native'

export const PageStyles = StyleSheet.create({
	parentView: {
		flex: 1
	},
	scrollView: {
		paddingVertical: '3rem'
	}
});
