import { StyleSheet } from 'react-native'
import { shadow } from 'styles/App'

export const parentViewRight = '1rem';
export const parentViewBottom = '1rem';
export const parentViewWithHomeFooterBottom = '5rem';

export const LoaderStyles = StyleSheet.create({
	parentView: {
		opacity: 0,
		position: 'fixed',
		right: parentViewRight,
		bottom: parentViewBottom,
		width: 75,
		height: 75,
		zIndex: 1000,
		alignItems: 'center',
		justifyContent: 'center',
		boxShadow: shadow.primary,
		borderRadius: 50,
		overflow: 'hidden'
	},
	parentViewWithHomeFooter: {
		bottom: parentViewWithHomeFooterBottom
	}
});
