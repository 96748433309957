import { IconCheckboxComponent, IconEnvelopeComponent, IconFavouriteComponent, IconFlashComponent, IconPlaceHolderComponent, IconStatusComponent } from 'components/atoms/Icons'

import { FA5Style } from '@expo/vector-icons/build/FontAwesome5';

export const IconSystemConstants = {
	HOME: 'system_home',
	CALENDAR: 'system_calendar',
	CALENDAR_PLUS: 'system_calendar_plus',
	PERSONS: 'system_persons',
	PERSON: 'system_person',
	PERSON_CHECK: 'system_person_check',
	CLOSE: 'system_close',
	TRASH: 'system_trash',
	CHECKBOX: 'system_checkbox',
	INFO: 'system_info',
	EVENT: 'system_event',
	FAVOURITE: 'system_favourite',
	INPUT_RESET: 'system_input_reset',
	INPUT_SEARCH: 'system_input_search',
	ARROW_RIGHT: 'system_arrow_right',
	ARROW_LEFT: 'system_arrow_left',
	CARET_LEFT: 'system_caret_left',
	CARET_RIGHT: 'system_caret_right',
	QRCODE: 'system_qrcode',
	ENVELOPE: 'system_envelope',
	ERO: 'system_ero',
	CLOCK: 'system_clock',
	PRIVACY: 'system_privacy',
	USER_SETTINGS: 'system_user_settings',
	USER_CHECK: 'system_user_check',
	MANAGEMENT: 'system_management',
	SIGN_OUT: 'system_signout',
	ERROR: 'system_error',
	FLASH: 'system_flash',
	CAMERA: 'system_camera',
	SUCCESS: 'system_success',
	DETAIL: 'system_detail',
	MORE: 'system_more',
	WARNING: 'system_warning',
	SPINNER: 'system_spinner',
	HAMBURGER_DRAWER: 'system_hamburger_drawer',
	DOTS_VERTICAL: 'system_dots_vertical',
	CIRCLE_OUTLINE: 'system_circle_outline',
	STATUS: 'system_status',
	PHONE: 'system_phone',
	MOBILE_PHONE: 'system_mobile_phone',
	BUILDING: 'system_building',
	PLACEHOLDER: 'system_placeholder',
	MOVE: 'system_move',
	CANCEL: 'system_cancel',
	REPORT: 'system_report',
	CHART: 'system_chart',
	PLUS: 'system_plus',
	SAVE: 'system_save',
	SETTINGS: 'system_settings',
	WORK: 'system_work'
};

export const IconTimeslotConstants = {
	HOME: 'home',
	WORKPLACE: 'workplace',
	LUNCH: 'lunch',
	MEETING_ROOM: 'meeting_room',
	
	USER: 'user',
	
	CAR: 'car',
	BICYCLE: 'bicycle',
	MOTORCYCLE: 'motorcycle',
	SHUTTLEVAN: 'shuttle-van',
	TRAIN: 'train',
	SUBWAY: 'subway',
	TRUCK: 'truck',
	PARKING: 'parking',
	
	COCKTAIL: 'cocktail',
	COFFEE: 'coffee',
	WINEGLASS: 'wineglass',
	BEER: 'beer',
	GlASSCHEERS: 'glass-cheers',
	HAMBURGER: 'hamburger',
	
	HEADPHONES: 'headphones',
	HEADSET: 'headset',
	MICROPHONE: 'microphone',
	MUSIC: 'music',
	DESKTOP: 'desktop',
	TV: 'tv',
	KEYBOARD: 'keyboard',
	LAPTOP: 'laptop',
	PHONE: 'phone',
	MOBILE: 'mobile',
	VIDEO: 'video',
	CAMERA: 'camera',
	XBOX: 'xbox',
	PLAYSTATION: 'playstation',
	GAMEPAD: 'gamepad',
	
	RUNNING: 'running',
	WALKING: 'walking',
	HIKING: 'hiking',
	BIKING: 'biking',
	DUMBBELL: 'dumbbell',
	SWIMMER: 'swimmer',
	BASKETBALL: 'basketball-ball',
	CHESS: 'chess',
	YOGA: 'yoga',
	
	BABY: 'baby',
	CHALKBOARD: 'chalkboard',
	CLOCK: 'clock',
	ELECTRICITY: 'electricity',
	
	
	FLASK: 'flask',
	KEY: 'key',
	GRADUATION_CAP: 'graduation_cap',
	HEART: 'heart',
	LOCK: 'lock',
	RESTROOM: 'restroom',
	WHEELCHAIR: 'wheelchair',
	BED: 'bed',
	BOOK: 'book',
	BOOKREADER: 'bookreader',
	BRAIN: 'brain',
	COMMENT: 'comment',
	GUITAR: 'guitar',
	QUESTION_MARK: 'question_mark',
};

export const IconFonts = {
	FONTAWESOME: 'fontawesome',
	MATERIAL_COMMUNITY: 'material_community',
};

export const IconConstants = {
	// SYSTEM ICONS
	[ IconSystemConstants.HOME ]: {
		font: IconFonts.FONTAWESOME,
		name: 'home'
	},
	[ IconSystemConstants.CALENDAR ]: {
		font: IconFonts.FONTAWESOME,
		fontStyle: FA5Style.solid,
		name: 'calendar'
	},
	[ IconSystemConstants.CALENDAR_PLUS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'calendar-plus'
	},
	[ IconSystemConstants.PERSONS ]: {
		font: IconFonts.FONTAWESOME,
		fontStyle: FA5Style.solid,
		name: 'users'
	},
	[ IconSystemConstants.PERSON ]: {
		font: IconFonts.FONTAWESOME,
		fontStyle: FA5Style.solid,
		name: 'user'
	},
	[ IconSystemConstants.ERO ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'medical-bag'
	},
	[ IconSystemConstants.CLOSE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'times-circle'
	},
	[ IconSystemConstants.TRASH ]: {
		font: IconFonts.FONTAWESOME,
		name: 'trash-alt'
	},
	[ IconSystemConstants.CHECKBOX ]: {
		function: IconCheckboxComponent
	},
	[ IconSystemConstants.INFO ]: {
		font: IconFonts.FONTAWESOME,
		name: 'info-circle'
	},
	[ IconSystemConstants.EVENT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'ticket-alt'
	},
	[ IconSystemConstants.FAVOURITE ]: {
		function: IconFavouriteComponent
	},
	[ IconSystemConstants.INPUT_RESET ]: {
		font: IconFonts.FONTAWESOME,
		name: 'times-circle'
	},
	[ IconSystemConstants.INPUT_SEARCH ]: {
		font: IconFonts.FONTAWESOME,
		name: 'search'
	},
	[ IconSystemConstants.ARROW_RIGHT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'chevron-right'
	},
	[ IconSystemConstants.ARROW_LEFT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'chevron-left'
	},
	[ IconSystemConstants.CARET_RIGHT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'caret-right'
	},
	[ IconSystemConstants.CARET_LEFT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'caret-left'
	},
	[ IconSystemConstants.QRCODE ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'qrcode-scan'
	},
	[ IconSystemConstants.ENVELOPE ]: {
		function: IconEnvelopeComponent
	},
	[ IconSystemConstants.CLOCK ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'medical-bag'
	},
	[ IconSystemConstants.CLOCK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'clock'
	},
	[ IconSystemConstants.PRIVACY ]: {
		font: IconFonts.FONTAWESOME,
		fontStyle: FA5Style.solid,
		name: 'user-secret'
	},
	[ IconSystemConstants.USER_SETTINGS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'user-cog'
	},
	[ IconSystemConstants.USER_CHECK ]: {
		font: IconFonts.FONTAWESOME,
		fontStyle: FA5Style.solid,
		name: 'user-check'
	},
	[ IconSystemConstants.MANAGEMENT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'cogs'
	},
	[ IconSystemConstants.SIGN_OUT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'sign-out-alt'
	},
	[ IconSystemConstants.MORE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'ellipsis-h'
	},
	[ IconSystemConstants.FLASH ]: {
		function: IconFlashComponent
	},
	[ IconSystemConstants.ERROR ]: {
		font: IconFonts.FONTAWESOME,
		name: 'question'
	},
	[ IconSystemConstants.WARNING ]: {
		font: IconFonts.FONTAWESOME,
		name: 'exclamation-circle'
	},
	[ IconSystemConstants.SPINNER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'spinner'
	},
	[ IconSystemConstants.SUCCESS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'check-circle'
	},
	[ IconSystemConstants.PERSON_CHECK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'user-check'
	},
	[ IconSystemConstants.DETAIL ]: {
		font: IconFonts.FONTAWESOME,
		name: 'eye'
	},
	[ IconSystemConstants.HAMBURGER_DRAWER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'bars'
	},
	[ IconSystemConstants.DOTS_VERTICAL ]: {
		font: IconFonts.FONTAWESOME,
		name: 'dots-vertical'
	},
	[ IconSystemConstants.CIRCLE_OUTLINE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'circle-outline'
	},
	[ IconSystemConstants.PHONE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'circle-outline'
	},
	[ IconSystemConstants.STATUS ]: {
		function: IconStatusComponent
	},
	[ IconSystemConstants.MOBILE_PHONE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'mobile-alt'
	},
	[ IconSystemConstants.BUILDING ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'office-building'
	},
	[ IconSystemConstants.MOVE ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'cursor-move'
	},
	[ IconSystemConstants.CANCEL ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'cancel'
	},
	[ IconSystemConstants.REPORT ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'file-excel'
	},
	[ IconSystemConstants.CHART ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'chart-line'
	},
	[ IconSystemConstants.PLUS ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'plus'
	},
	[ IconSystemConstants.SAVE ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'content-save-outline'
	},
	[ IconSystemConstants.SETTINGS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'cog'
	},
	[ IconSystemConstants.WORK ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'briefcase'
	},
	[ IconSystemConstants.PLACEHOLDER ]: {
		function: IconPlaceHolderComponent
	},
	
	[ IconSystemConstants.CAMERA ]: {
		font: IconFonts.FONTAWESOME,
		name: 'camera'
	},
	
	
	// TIMESLOT ICONS (basic)
	[ IconTimeslotConstants.WORKPLACE ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'chair-rolling'
	},
	[ IconTimeslotConstants.LUNCH ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'silverware-fork-knife'
	},
	[ IconTimeslotConstants.MEETING_ROOM ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'google-classroom'
	},
	[ IconTimeslotConstants.USER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'user'
	},
	
	// TIMESLOT ICONS (transport)
	[ IconTimeslotConstants.CAR ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'car'
	},
	[ IconTimeslotConstants.BICYCLE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'bicycle'
	},
	[ IconTimeslotConstants.MOTORCYCLE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'motorcycle'
	},
	[ IconTimeslotConstants.SHUTTLEVAN ]: {
		font: IconFonts.FONTAWESOME,
		name: 'shuttle-van'
	},
	[ IconTimeslotConstants.TRAIN ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'train'
	},
	[ IconTimeslotConstants.SUBWAY ]: {
		font: IconFonts.FONTAWESOME,
		name: 'subway'
	},
	[ IconTimeslotConstants.TRUCK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'truck'
	},
	[ IconTimeslotConstants.PARKING ]: {
		font: IconFonts.FONTAWESOME,
		name: 'parking'
	},
	
	// TIMESLOT ICONS (drinks)
	[ IconTimeslotConstants.COCKTAIL ]: {
		font: IconFonts.FONTAWESOME,
		name: 'cocktail'
	},
	[ IconTimeslotConstants.COFFEE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'coffee'
	},
	[ IconTimeslotConstants.WINEGLASS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'wine-glass-alt'
	},
	[ IconTimeslotConstants.BEER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'beer'
	},
	[ IconTimeslotConstants.GlASSCHEERS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'glass-cheers'
	},
	[ IconTimeslotConstants.HAMBURGER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'hamburger'
	},
	
	// TIMESLOT ICONS (audio/video/gaming)
	[ IconTimeslotConstants.HEADPHONES ]: {
		font: IconFonts.FONTAWESOME,
		name: 'headphones'
	},
	[ IconTimeslotConstants.HEADSET ]: {
		font: IconFonts.FONTAWESOME,
		name: 'headset'
	},
	[ IconTimeslotConstants.MICROPHONE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'microphone'
	},
	[ IconTimeslotConstants.MUSIC ]: {
		font: IconFonts.FONTAWESOME,
		name: 'itunes-note'
	},
	[ IconTimeslotConstants.DESKTOP ]: {
		font: IconFonts.FONTAWESOME,
		name: 'desktop'
	},
	[ IconTimeslotConstants.TV ]: {
		font: IconFonts.FONTAWESOME,
		name: 'tv'
	},
	[ IconTimeslotConstants.KEYBOARD ]: {
		font: IconFonts.FONTAWESOME,
		name: 'keyboard'
	},
	[ IconTimeslotConstants.LAPTOP ]: {
		font: IconFonts.FONTAWESOME,
		name: 'laptop'
	},
	[ IconTimeslotConstants.PHONE ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'phone'
	},
	[ IconTimeslotConstants.MOBILE ]: {
		font: IconFonts.FONTAWESOME,
		name: 'mobile-alt'
	},
	[ IconTimeslotConstants.CAMERA ]: {
		font: IconFonts.FONTAWESOME,
		name: 'camera'
	},
	[ IconTimeslotConstants.VIDEO ]: {
		font: IconFonts.FONTAWESOME,
		name: 'video'
	},
	[ IconTimeslotConstants.XBOX ]: {
		font: IconFonts.FONTAWESOME,
		name: 'xbox'
	},
	[ IconTimeslotConstants.PLAYSTATION ]: {
		font: IconFonts.FONTAWESOME,
		name: 'playstation'
	},
	[ IconTimeslotConstants.GAMEPAD ]: {
		font: IconFonts.FONTAWESOME,
		name: 'gamepad'
	},
	
	// TIMESLOT ICONS (sports/activities)
	[ IconTimeslotConstants.RUNNING ]: {
		font: IconFonts.FONTAWESOME,
		name: 'running'
	},
	[ IconTimeslotConstants.WALKING ]: {
		font: IconFonts.FONTAWESOME,
		name: 'walking'
	},
	[ IconTimeslotConstants.HIKING ]: {
		font: IconFonts.FONTAWESOME,
		name: 'hiking'
	},
	[ IconTimeslotConstants.BIKING ]: {
		font: IconFonts.FONTAWESOME,
		name: 'biking'
	},
	[ IconTimeslotConstants.DUMBBELL ]: {
		font: IconFonts.FONTAWESOME,
		name: 'dumbbell'
	},
	[ IconTimeslotConstants.SWIMMER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'swimmer'
	},
	[ IconTimeslotConstants.BASKETBALL ]: {
		font: IconFonts.FONTAWESOME,
		name: 'basketball-ball'
	},
	[ IconTimeslotConstants.CHESS ]: {
		font: IconFonts.FONTAWESOME,
		name: 'chess'
	},
	[ IconTimeslotConstants.YOGA ]: {
		font: IconFonts.MATERIAL_COMMUNITY,
		name: 'yoga'
	},
	
	// TIMESLOT ICONS (OTHERS)
	[ IconTimeslotConstants.BABY ]: {
		font: IconFonts.FONTAWESOME,
		name: 'baby'
	},
	[ IconTimeslotConstants.CHALKBOARD ]: {
		font: IconFonts.FONTAWESOME,
		name: 'chalkboard'
	},
	[ IconTimeslotConstants.CLOCK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'clock'
	},
	[ IconTimeslotConstants.ELECTRICITY ]: {
		font: IconFonts.FONTAWESOME,
		name: 'bolt'
	},
	[ IconTimeslotConstants.FLASK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'flask'
	},
	[ IconTimeslotConstants.KEY ]: {
		font: IconFonts.FONTAWESOME,
		name: 'key'
	},
	[ IconTimeslotConstants.GRADUATION_CAP ]: {
		font: IconFonts.FONTAWESOME,
		name: 'graduation-cap'
	},
	[ IconTimeslotConstants.HEART ]: {
		font: IconFonts.FONTAWESOME,
		name: 'heart'
	},
	[ IconTimeslotConstants.LOCK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'lock'
	},
	[ IconTimeslotConstants.RESTROOM ]: {
		font: IconFonts.FONTAWESOME,
		name: 'restroom'
	},
	[ IconTimeslotConstants.WHEELCHAIR ]: {
		font: IconFonts.FONTAWESOME,
		name: 'wheelchair'
	},
	[ IconTimeslotConstants.BED ]: {
		font: IconFonts.FONTAWESOME,
		name: 'bed'
	},
	[ IconTimeslotConstants.BOOK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'book'
	},
	[ IconTimeslotConstants.BOOKREADER ]: {
		font: IconFonts.FONTAWESOME,
		name: 'book-reader'
	},
	[ IconTimeslotConstants.BRAIN ]: {
		font: IconFonts.FONTAWESOME,
		name: 'brain'
	},
	[ IconTimeslotConstants.COMMENT ]: {
		font: IconFonts.FONTAWESOME,
		name: 'comment'
	},
	[ IconTimeslotConstants.GUITAR ]: {
		font: IconFonts.FONTAWESOME,
		name: 'guitar'
	},
	[ IconTimeslotConstants.QUESTION_MARK ]: {
		font: IconFonts.FONTAWESOME,
		name: 'question'
	}
}