import { Platform } from "react-native";

export const platformConstants = {
	WEB: "web",
	IOS: "ios",
	ANDROID: "android",
	NATIVE: "native"
};

export default class HelperPlatform {
	
	static isWeb = () => {
		return Platform.OS === platformConstants.WEB
	};
	static isNotWeb = () => {
		return !this.isWeb()
	}
	
	static isNative = () => {
		return Platform.OS === platformConstants.NATIVE
	};
	static isNotNative = () => {
		return !this.isNative()
	};
	
	static isIOS = () => {
		return Platform.OS === platformConstants.IOS
	};
	
	static isAndroid = () => {
		return Platform.OS === platformConstants.ANDROID
	};
	
	static isIpad = () => {
		return Platform.isPad;
	}
	
	static select = ( options ) => {
		if ( options[ 'ipad' ] && this.isIpad() ) {
			return options[ 'ipad' ];
		}
		
		return Platform.select( options );
	}
}
