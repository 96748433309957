import { StyleSheet } from 'react-native'
import HelperPlatform from 'utils/helper/platform'
import _ from "lodash";

export default class HelperStyles {
	
	static toObject = ( styles ) => {
		const isWeb = HelperPlatform.isWeb();
		
		let object = {};
		
		if ( !_.isArray( styles ) ) {
			styles = [ styles ]
		}
		
		_.forEach( styles, ( style ) => {
			
			if ( _.isArray( style ) ) {
				style = this.toObject( style )
			}
			
			object = {
				...object, ...( isWeb ? StyleSheet.flatten( style ) : style )
			};
		} );
		
		return object;
	}
	
	static isColorDark = ( color ) => {
		
		// convert color to rgb if hex color
		if ( color.indexOf( '#' ) === 0 ) {
			color = this.hexToRgb( color )
		}
		
		const rgb = color.match( /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/ );
		const r = rgb[ 1 ];
		const g = rgb[ 2 ];
		const b = rgb[ 3 ];
		const brightness = ( ( r * 299 ) + ( g * 587 ) + ( b * 114 ) ) / 1000;
		return brightness < 130;
	}
	
	static hexToRgb( color ){
		color = color.replace( '#', '' );
		
		const bigint = parseInt( color, 16 );
		const r = ( bigint >> 16 ) & 255;
		const g = ( bigint >> 8 ) & 255;
		const b = bigint & 255;
		
		return `rgb(${r}, ${g}, ${b})`;
	}
	
	static generateHexColor(){
		const randomColor = Math.floor(Math.random()*16777215).toString(16);
		return `#${randomColor}`;
	}
}
