import React from 'react'
import isEqual from 'react-fast-compare'
import { View } from 'react-native'
import { PageBlockViewStyles } from 'styles/components/atoms/PageBlockView'

const PageBlockView = ( { style = null, children } ) => {
	let styles = [ PageBlockViewStyles.view ];
	
	if( style ){
		styles.push( style )
	}
	
	return <View style={ styles }>{children}</View>
}

export default React.memo( PageBlockView, isEqual );