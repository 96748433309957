import { DefaultTheme } from '@react-navigation/native'
import i18n from 'utils/i18n'

export const navigationContainerProps = {
	theme: {
		...DefaultTheme,
		dark: false,
		colors: {
			...DefaultTheme.colors,
		}
	},
	documentTitle: {
		formatter: (options, route) => `${i18n.t('app_name')}`,
	}
}
