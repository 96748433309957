import { TransitionPresets } from '@react-navigation/stack'

export const tabBarProps = {
	screenOptions: {
		headerShown: false,
	}
}

export const tabBarScreenProps = {
	options: {
		gestureDirection: 'horizontal',
	}
}

const animationConfig = {
	animation: 'slide',
	config: {
		stiffness: 4000,
		damping: 500,
		mass: 3,
		overshootClamping: true,
		restDisplacementThreshold: 0.01,
		restSpeedThreshold: 0.01,
	},
};

export const stackProps = {
	screenOptions: {
		headerShown: false,
		animationEnabled: true,
		cardShadowEnabled: false
	}
}

export const stackScreenProps = {
	options: {
		gestureDirection: 'horizontal',
		...TransitionPresets.SlideFromRightIOS
	}
}
