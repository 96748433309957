import _ from 'lodash';

export default class HelperObject {
	
	static groupBy( payload, key ){
		const collection = {};
		
		_.forEach( payload, ( item ) => {
			const guid = _.get( item, key, null );
			
			if( guid !== null ) {
				collection[ guid ] = item;
			}
		});
		
		return collection;
	}
	
	// Simple function to group payload by guid
	static groupByGuid( payload ) {
		return this.groupBy( payload, 'guid' );
	}
	
	static isValid = (o) => {
		return typeof o !== 'undefined' && o !== null && o !== false && o !== 'false';
	};
}
