import Constants from 'expo-constants';
import compareVersions from "compare-versions";
import HelperPlatform from 'utils/helper/platform'

export default class HelperVersion {
	
	static get = () => {
		if( HelperPlatform.isWeb() ) {
			return `${ Constants.manifest.version }`
		}
		
		return `${ Constants.nativeAppVersion } (${ Constants.manifest.version })`
	}

	static getNativeAppVersion = () => {
		return Constants.nativeAppVersion;
	}

	static getBuildVersion = () => {
		return Constants.manifest.version;
	}
	
	static isTestVersion = () => {
		return Constants.manifest.version.includes('beta');
	}
	
	static compareVersions = (a, b) => {
		if (a === b) {
			return 0;
		}
		
		let a_components = a.split(".");
		let b_components = b.split(".");
		
		let len = Math.min(a_components.length, b_components.length);
		
		// loop while the components are equal
		for (let i = 0; i < len; i++) {
			// A bigger than B
			if (parseInt(a_components[i]) > parseInt(b_components[i])) {
				return 1;
			}
			
			// B bigger than A
			if (parseInt(a_components[i]) < parseInt(b_components[i])) {
				return -1;
			}
		}
		
		// If one's a prefix of the other, the longer one is greater.
		if (a_components.length > b_components.length) {
			return 1;
		}
		
		if (a_components.length < b_components.length) {
			return -1;
		}
		
		// Otherwise they are the same.
		return 0;
	}
	
	
	/**
	 * Function for comparing the build version to the given version
	 * @param version
	 * @returns {boolean}
	 */
	static compareBuild = ( version ) => {
		return compareVersions.compare( Constants.manifest.version, version, '>=' );
	}
	
	/**
	 * Function for comparing the app version to the given version
	 * @param version
	 * @returns {boolean}
	 */
	static compareApp = ( version ) => {
		return compareVersions.compare( Constants.nativeAppVersion, version, '>=' );
	}
}
