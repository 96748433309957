import React, { useEffect, useState } from 'react';
import _ from 'lodash'
import { NavigationContainer } from '@react-navigation/native';
import i18n from "utils/i18n";
import { useDispatch, useSelector } from "react-redux";
import { dataConstants } from "services/redux/data/constants";
import { navigationContainerProps } from 'utils/helper/navigation-container'
import { createStackNavigator } from '@react-navigation/stack'
import { stackProps, stackScreenProps } from 'utils/helper/navigation'
import { AppRoutes } from 'constants/AppRoutes'
import { setData, setDataMulti } from "services/redux/data/actions";
import SceneHomeDashboardIndex from 'scenes/home/dashboard'
import Loader from 'components/organisms/Loader'
import Modal from 'components/organisms/Modal'

const RootStack = createStackNavigator();


const HomeNavigator = () => {
	const dispatch = useDispatch()
	
	useEffect( () => {
		dispatch( setData( dataConstants.APP_LOADED, true ) );
	}, [] );
	
	const routes = {
		[ AppRoutes.HOME_DASHBOARD_INDEX ]: SceneHomeDashboardIndex
	};
	
	let mainScreens = {};
	
	_.forEach( routes, ( component, key ) => {
		mainScreens[ key ] = {
			title: i18n.t( `titles.${key.replace('-','_')}` ),
			component: component,
		}
	});
	
	return (
		<NavigationContainer
			{ ...navigationContainerProps }>
			
			<RootStack.Navigator
				{ ...stackProps }>
				
				{ _.map( mainScreens, ( screen, name ) => (
					<RootStack.Screen key={ `screen_${name}` } name={ name } component={ screen.component } { ...stackScreenProps } />
				))}
			</RootStack.Navigator>
			
			<Loader />
			<Modal />
		</NavigationContainer>
	)
}

export default HomeNavigator
