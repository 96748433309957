import { ADD_DATA, ADD_DATA_MULTI, REMOVE_DATA_ARRAY_ITEM, REMOVE_DATA_KEY, RESET_DATA, RESET_DATA_KEY, SET_DATA, SET_DATA_MULTI } from 'services/redux/action-types'
import _ from 'lodash';
import { dataConstants } from "services/redux/data/constants";

const initialState = {
	[ dataConstants.APP_LOADED ]: false,
	[ dataConstants.INITIAL_URL ]: null,
	[ dataConstants.CUSTOMER_STATISTICS ]: {},
	[ dataConstants.CUSTOMER_STATISTICS_UPDATED_ATS ]: {},
};

export default function data( state = initialState, action ) {
	
	const initialValue = initialState[ action.key ];
	const initialDefaultValue = _.isArray( initialValue ) ? [] : {};
	
	switch (action.type) {
		case SET_DATA:
			let newValue = action.payload;
			
			// Is it an object? Then 'copy' it
			if( _.isObject( initialValue ) ) {
				if( !_.isArray( initialValue ) ) {
					newValue = {
						...action.payload
					};
				} else {
					newValue = [
						...action.payload
					];
				}
			}
			
			return {
				...state,
				[action.key]: newValue
			};
			
		case SET_DATA_MULTI:
			return {
				...state,
				...action.payload
			};
		
		case ADD_DATA:
			
			// When the payload is empty do not merge them
			if( _.isEmpty( action.payload ) ) {
				return state;
			}
			
			const currentData = _.get( state, action.key, initialDefaultValue );
			
			// Reset payload keys first when set
			if( action.payload_reset_by_keys ) {
				_.forEach( _.keys( action.payload ), ( payloadKey ) => {
					delete currentData[ payloadKey ]
				})
			}
			
			return {
				...state,
				[ action.key ]: {
					...currentData,
					...action.payload
				}
			}
		
		case ADD_DATA_MULTI:
			let didUpdate = false;
			
			// Combine the old state with the new values
			_.forEach( action.payload, ( values, key ) => {
				
				if( !_.isEmpty( values ) ) {
					didUpdate = true;
					
					state[ key ] = {
						..._.get( state, key, initialDefaultValue ),
						...values
					}
				}
			});
			
			// Modify when updated
			if( didUpdate ) {
				state = {
					...state
				};
			}
			
			return state;
			
		case RESET_DATA_KEY:
			return {
				...state,
				...{
					[action.key]: {
						..._.get( state, action.key, initialDefaultValue ),
						[ action.payload ]: action.value
					}
				}
			}
			
		case REMOVE_DATA_KEY:
			const data = _.get( state, action.key, initialDefaultValue );
			delete data[ action.payload ];
			
			return {
				...state,
				...{
					[action.key]: {
						...data
					}
				}
			}
			
		case REMOVE_DATA_ARRAY_ITEM:
			const array = _.pull( _.get( state, action.key, initialDefaultValue ), action.payload );
			
			return {
				...state,
				...{
					[action.key]: array
				}
			}

		case RESET_DATA:
			return initialState;
			
		default:
			// Nothing
	}
	
	return state;
}