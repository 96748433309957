import React from 'react'
import _ from "lodash";
import { FontAwesome5, MaterialCommunityIcons } from '@expo/vector-icons'
import { FA5Style } from '@expo/vector-icons/build/FontAwesome5';
import { View } from 'react-native'
import HelperStyles from 'utils/helper/styles'

/*---------------------------------
    More 'complex' icons
*/

export const IconPlaceHolder = ( props = {} ) => () => {
	props = createProps( props );
	
	const size =  _.get( props, 'size', 18 );
	
	const style = {
		...props[ 'style' ],
		width: size,
		height: size
	};
	
	return <View style={ style } />;
}
export const IconPlaceHolderComponent = ( props = {} ) => { return IconPlaceHolder( props )(); }


// Status icon
export const IconStatus = ( props = {} ) => () => {
	let iconName = _.get( props, 'checked', false ) ? 'circle' : 'circle'; // checked equals read
	let style = _.get( props, 'checked', false ) ? FA5Style.solid : FA5Style.regular;
	
	return renderFontAwesomeIcon( iconName, props, style );
}
export const IconStatusComponent = ( props = {}  ) => { return IconStatus( props )(); }


// Envelope icon
export const IconEnvelope = ( props = {} ) => () => {
	let iconName    = _.get( props, 'checked', false ) ? 'envelope-open-text' : 'envelope' ; // checked equals read
	let style       = _.get( props, 'checked', false ) ? FA5Style.solid : FA5Style.regular;
	
	return renderFontAwesomeIcon( iconName , props, style);
}
export const IconEnvelopeComponent = ( props = {} ) => { return IconEnvelope( props )(); }


// Favourite icon
export const IconFavourite = ( props = {} ) => () => {
	let faStyle = FA5Style.regular;
	if( _.get( props, 'checked', false ) ){
		faStyle = FA5Style.solid;
	}
	return renderFontAwesomeIcon( 'heart' , props, faStyle );
}
export const IconFavouriteComponent = ( props = {} ) => { return IconFavourite( props )(); }


export const IconFlash = ( props = {} ) => () => {
	let name = 'flash-off';
	if( _.get( props, 'flashOn', false ) ){
		name = 'flash';
	}
	return renderMaterialCommunityIcon( name , props );
}
export const IconFlashComponent = ( props = {} ) => { return IconFlash( props )(); }

/*
// Flashlight icon
export const IconFlash = ( props = {} ) => () => { return renderMaterialCommunityIcon( 'flash', props ); }
export const IconFlashComponent = ( props = {} ) => {return IconFlash( props )(); }

export const IconFlashOff = ( props = {} ) => () => { return renderMaterialCommunityIcon( 'flash-off', props ); }
export const IconFlashOffComponent = ( props = {} ) => {return IconFlashOff( props )(); }

 */

// Checkbox icon
export const IconCheckbox = ( props = {} ) => () => {
	let iconName = _.get( props, 'checked', false ) ? 'check-square' : 'square';
	return renderFontAwesomeIcon( iconName , props );
}
export const IconCheckboxComponent = ( props = {} ) => { return IconCheckbox( props )(); }

const createProps = ( props = {} ) => {
	let createdProps = { ...props };
	
	const size =  _.get( props, 'size', 18 );
	const color =  _.get( props, 'color',  _.get( props, [ 'style', 'color' ],  _.get( props, [ 'fill' ], '#000' ) ) ); // try to find color property, color in style or set it to black
	
	// create the proper style properties
	const style = {
		fontSize: size,
		color: color,
		
		flex: 0,
		justifyContent: 'center',
		alignItems: 'center',
		alignSelf: 'center',
		width: 'auto',
		height: 'auto',
	};
	
	// write it to style
	createdProps[ 'style' ] = HelperStyles.toObject([
		style,
		_.get( props, 'style', {} ),
	]);
	
	return createdProps;
}

export const renderMaterialCommunityIcon = ( name, props = {} ) => {
	return (
		<MaterialCommunityIcons
			name={ name }
			{...createProps( props )}
		/>
	);
}

export const renderFontAwesomeIcon = ( name, props = {}, faStyle = FA5Style.regular ) => {
	switch( faStyle ){
		case FA5Style.regular:
			return (
				<FontAwesome5
					name={ name }
					{...createProps( props )}
				/>
			);
			
		case FA5Style.solid:
			return (
				<FontAwesome5
					solid
					name={ name }
					{...createProps( props )}
				/>
			);
			
		case FA5Style.light:
			return (
				<FontAwesome5
					light
					name={ name }
					{...createProps( props )}
				/>
			);
			
		case FA5Style.brand:
			return (
				<FontAwesome5
					brand
					name={ name }
					{...createProps( props )}
				/>
			);
	}
}