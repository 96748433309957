import React from 'react'
import isEqual from 'react-fast-compare'
import { View } from 'react-native'
import { ContentCenteredStyles } from 'styles/components/atoms/ContentCentered'

const ContentCentered = ( props ) => {
	return (
		<View style={ ContentCenteredStyles.container }>
			{props.children}
		</View>
	);
}

export default React.memo( ContentCentered, isEqual );