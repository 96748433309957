import React from "react";
import { StyleSheet } from "react-native";

export const colors = {
	primary: {
		primary: '#FF9800',
		dark: '#EF6C00',
		darker: '#EF6C00',
		light: '#FFCC80',
		subtle: '#fff1db',
	},
	secondary: {
		primary: '#FF7900',
	},
	base: {
		light: '#ffffff',
		dark: '#434343'
	},
	grey:{
		light: '#E8EEE9',
		lighter: '#F5F8F6',
		primary: '#DFDFDF',
		dark: '#808080'
	},
	warning: {
		border: '#f3c9c9',
		background: '#cc4800'
	},
	blue: {
		primary: '#2196F3',
		dark: '#1565C0',
		light: '#90CAF9',
	},
	purple: {
		primary: '#9C27B0',
		dark: '#6A1B9A',
		light: '#CE93D8',
	},
	green: {
		primary: '#4CAF50',
		dark: '#2E7D32',
		light: '#A5D6A7',
	},
}

export const gradients = {
	orange: {
		to: '#F89B49',
		from: '#F97D1C'
	},
	background: {
		to: '#edf1ee',
		from: '#ffffff'
	}
}

export const shadow = {
	primary: '0 2px 6px 0 rgb(0 0 0 / 19%)',
	primaryTop: '0 -6px 6px 0 rgb(0 0 0 / 19%)',
	subtle: '0 2px 6px 0 rgb(0 0 0 / 5%)'
}

export const borderRadius = {
	primary: '.8rem',
	large: '3em'
}

export const fontSizes = {
	extra_small: 10,
	small: 12,
	medium: 16,
	medium_large: 20,
	large: 24,
	extra_large: 32,
	extra_extra_large: 100
}

export const Fonts = StyleSheet.create({
	title: {
		fontSize: fontSizes.extra_large,
		fontWeight: '700',
		fontFamily: 'HeinekenBold',
		color: colors.base.dark,
		marginBottom: fontSizes.extra_large / 2,
		flex: 0,
		lineBreak: 'normal',
		hyphens: 'auto'
	},
	
	description: {
		fontSize: fontSizes.medium,
		marginBottom: '1rem',
		lineHeight: 22,
		fontFamily: 'Heineken',
		color: colors.base.dark,
		fontWeight: '400',
	},
	
	note: {
		fontSize: fontSizes.small,
		marginBottom: '1rem',
		lineHeight: 22,
		fontFamily: 'HeinekenItalic',
		color: colors.base.dark,
		fontWeight: '400',
	},
	
	textColorDark: {
		color: colors.base.dark,
	},
	textColorLight: {
		color: colors.base.light,
	},
	
	fontSizeExtraSmall: {
		fontSize: fontSizes.extra_small,
		marginBottom: fontSizes.extra_small / 2,
	},
	fontSizeSmall: {
		fontSize: fontSizes.small,
		marginBottom: fontSizes.small / 2,
	},
	fontSizeMedium: {
		fontSize: fontSizes.medium,
		marginBottom: fontSizes.medium / 2,
	},
	fontSizeMediumLarge: {
		fontSize: fontSizes.medium_large,
		marginBottom: fontSizes.medium_large / 2,
	},
	fontSizeLarge: {
		fontSize: fontSizes.large,
		marginBottom: fontSizes.large / 3
	},
	fontSizeExtraLarge: {
		fontSize: fontSizes.extra_large,
		marginBottom: fontSizes.extra_large / 3,
	},
	fontSizeExtraExtraLarge: {
		fontSize: fontSizes.extra_extra_large
	},
	
	noMargins: {
		marginBottom: undefined
	},
	
	button: {
		fontSize: '1.25em',
		fontFamily: 'HeinekenBold',
	},
	centered: {
		textAlign: 'center'
	},
	strong: {
		fontFamily: 'HeinekenBold'
	}
});

export const Blocks = StyleSheet.create({
	content: {
		position: 'relative',
		boxShadow: shadow.primary,
		borderRadius: borderRadius.primary,
		padding: '1rem',
		marginVertical: '1.5rem',
		backgroundColor: colors.base.light,
		overflow: 'hidden',
		flex: 1
	},
	imageView:{
		marginTop: '-1rem',
		marginHorizontal: '-1rem',
		marginBottom: '1rem',
		position: 'relative',
		height: 200,
		overflow: 'hidden'
	},
	image: {
		width: '100%',
		height: '100%',
		resizeMode: 'contain'
	},
	imageBlurView: {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		bottom: 0,
		padding: 10,
		alignItems: 'center',
		justifyContent: 'center',
	},
	imageBackgroundImage: {
		position: 'absolute',
		left: -100,
		right: -100,
		top: -100,
		bottom: -100,
		resizeMode: 'cover',
	}
});

export const Buttons = StyleSheet.create({
	primaryView: {
		alignSelf: 'flex-start',
		width: 'auto',
		boxShadow: shadow.primary,
		borderRadius: borderRadius.large,
		marginTop: '1rem',
		alignItems: 'center',
		cursor: 'pointer'
	},
	primaryText: {
		textAlign: 'center',
	},
	
	primaryViewStretch: {
		alignSelf: 'stretch',
	},
	
	primaryViewSmall:{
		paddingVertical: '.6rem',
		paddingHorizontal: '.6rem'
	},
	primaryViewMedium:{
		paddingVertical: '.8rem',
		paddingHorizontal: '1.2rem'
	},
	
	primaryTextSmall:{
		fontSize: fontSizes.small,
	},
	primaryTextMedium:{
		fontSize: fontSizes.medium,
	},
	
	primaryViewGreen:{
		backgroundColor: colors.primary.primary,
	},
	primaryViewWhite:{
		backgroundColor: colors.base.light
	},
	primaryViewOrange:{
		backgroundColor: colors.secondary.primary
	},
	
	primaryTextGreen:{
		color: colors.primary.primary
	},
	primaryTextWhite:{
		color: colors.base.light
	}
});
